define('emberjs-calculator/components/theme-option-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    currentTheme: Ember.inject.service(),

    actions: {
      setTheme(theme) {
        this.get("themes").forEach(theme => {
          theme.set('isSelected', false);
        });
        theme.set('isSelected', true);
        this.get('currentTheme').setTheme(theme['class-name']);
        this.get('router').transitionTo('/');
      }
    }
  });
});