define('emberjs-calculator/routes/themes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      this.get('store').push({
        data: [{
          id: 0,
          type: 'theme',
          attributes: {
            name: 'Default',
            "class-name": 'default',
            isSelected: true
          }
        }]
      });

      let themes = this.get('store').findAll('theme');
      return themes;
    }
  });
});