define('emberjs-calculator/services/current-theme', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentTheme: 'default',

    name: Ember.computed('currentTheme', function () {
      const theme = this.get('currentTheme');
      return `${theme}`;
    }),

    themeChanged: Ember.observer('currentTheme', function () {
      this.notifyPropertyChange('name');
    }),

    setTheme(theme) {
      this.set('currentTheme', Ember.isEmpty(theme) ? 'default' : theme);
    }
  });
});